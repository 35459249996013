const clientId = process.env.REACT_APP_CLIENT_ID || "C58e1c4b8e3f87351fca8e1deba17e0134ea28976";
const envDeploy = process.env.REACT_APP_STAGING || "development";
console.log("clientId : " + clientId + " env :" + envDeploy);

const getFedUrl = () => {

    let url = "https://preprod.idpdecathlon.oxylane.com";

    if (envDeploy === 'production') {
        url = "https://idpdecathlon.oxylane.com";
    }

    return url;
}

const getApiParameters = () => {

    let url = "https://parameter-api.ig1.mdm-staging.kdm-mdm-staging-7nwv.decathlon.io/preprod";

    if (envDeploy === 'production') {
        url = "https://parameter-prod-dot-mobile-retail-prod-eu-wtpk.appspot.com/prod";
    }

    return url;

}

const getClientIDAndEnv = () => {

    let clientId = "C58e1c4b8e3f87351fca8e1deba17e0134ea28976";
    let env = "preprod";

    if (envDeploy === 'production') {
        clientId = "Cf1c15b4c2caab4368a70fab5874238d63c0391da";
        env = "production";
    }

    return {clientId : clientId , env : env}

}


module.exports = { getFedUrl , getApiParameters, getClientIDAndEnv }