import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(1),
    fontWeight : 'bold'
  },
  alignRightHeader : {
    fontSize: '15px',
    textAlign: 'right',
    paddingRight : '20px',
    marginTop: theme.spacing(1),
  }

});


class Header extends React.Component {


    render() {

      const { classes } = this.props
      const userInfo = this.props.userInfo      

      let colorChip = "primary"
      if (process.env.REACT_APP_STAGING === 'developpement' || process.env.REACT_APP_STAGING === "preprod") { colorChip = "secondary"}

      return (

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Chip label={process.env.REACT_APP_STAGING} className={classes.chip} color={colorChip} /> 
          </Grid>
          <Grid item xs={4}>
            &nbsp;
          </Grid>
          <Grid item xs={5} className={classes.alignRightHeader} >
            {userInfo.displayName} | User : {userInfo.sub} | Job : {userInfo.jobname}
          </Grid>
        </Grid>

        )
    }

}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles) (Header);